import React from 'react';

const UnderConstructionPage = () => {
  return (
    <div style={{ color: 'white', fontSize: '4rem', backgroundColor: 'black', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      Sorry, This page is not available right now
    </div>
  );
};

export default UnderConstructionPage;
